.Sidebar {
  margin-top: 5px;
  margin-left: 10px;
  height: 98vh;
  width: 350px;
  background-color: #2f4050;
  border-radius: 10px;

  @media all and (max-width: 768px) {
    display: none;
  }
  &__logo {
    width: 200px;
    width: 150px;
    padding: 10px;
    margin: 10px;
  }
  &__list {
    padding: 0;
    height: auto;
    width: 100%;
  }
  #active {
    background-color: #1a242f;
  }
  &__row {
    width: 100%;
    height: 60px;
    list-style: none;
    margin: 0%;
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: center;
    align-items: center;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;

    &:hover {
      background-color: #1a242f;
      cursor: pointer;
    }
    #icon {
      flex: 30%;
      display: grid;
      place-items: center;
    }
    #title {
      flex: 70%;
    }
  }
}
